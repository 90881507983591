.post-container {
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.67); 
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.67);
    text-align: left;
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.like-btn:hover {
    color: rgb(244, 129, 32);
}

.like-btn-filled {  
    color: rgb(244, 129, 32);
}

.like-count {
    margin-left: 4px;
    color: rgb(244, 129, 32);
    display: inline-block;
}

.post-img {
    width: 100%;
}

p {
    margin-left: 5px;
}

a {
    display: block;
}