.Posts {
    max-width: 80vh;
    margin: auto;
}

textarea {
    width: 70%;
    height: 200px;
    resize: none;
}

.create-post-container {
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.67); 
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.67);
    border-radius: 5px;
    padding: 0px 10px 10px 10px;
    margin-top: 10px;
}

label {
    margin: auto;
    margin-top: 10px;
    display: block;
}

input {
    display: block;
    width: 50%;
}

input[type=submit] {
    margin-top: 10px;
    width: auto;
}

.required-field {
    color: red;
}

.post-username {
    margin-top: 0px;
    color: rgb(128, 128, 128);
}

#order-select-label {
    display: inline-block;
}

#sort-container {
    margin-top: 100px;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.67); 
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.67);
    border-radius: 5px;
    padding-bottom: 10px;
    padding-left: 10px;
}


h1 {
    margin-bottom: 0px;
    padding-top: 5px;
}