.navbar {
    width: 100%;
    background-color: rgb(244, 129, 32);
    height: 60px;
    display: flex;
    transition: all .7s ease-in;
    z-index: 1;
    -webkit-box-shadow: 0px 10px 15px -3px #707070; 
    box-shadow: 0px 10px 15px -3px #707070;
}

.scrolled {
    position: fixed;
    top: 0;
    left: 0;
}

.logo-container {
    margin-top: 10px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: white;
    border-radius: 5px;
    height: 40%;
    font-size: larger;
    margin-left: 5px;
    width: 125px;
}

.logo-text {
    color:rgb(244, 129, 32);
    position: absolute;
    top: 10px;
}

.logo-camera {
    color: rgb(244, 129, 32); 
}